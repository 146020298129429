import moment from 'moment';
import req, { get } from '../utils/request';
// import { message } from 'antd';
import { Model } from 'dva';
const helloTime = new Map([ // 各个时段欢迎语
  [[3, 4, 5], ['凌晨了', '注意休息']],
  [[6, 7], ['早上好', '该起床上班了']],
  [[8, 9, 10, 11], ['上午好', '新的一天，新的起点，新的动力，从评审通开始~']],
  [[12, 13], ['中午了', '该吃饭了']],
  [[14, 15, 16, 17], ['下午好', '喝点什么吧，然后打起精神']],
  [[18, 19], ['傍晚好', '注意休息']],
  [[20, 21, 22], ['晚上好', '注意休息']],
  [[23, 0, 1, 2], ['深夜了', '注意休息']]
])
const Workdynamics: Model = {
  namespace: 'Workdynamics',
  state: {
    selectedKeys: [window.location.pathname.split('/')[2] ? window.location.pathname.split('/')[2] : 'dynamics'],
    // openKeys: [contact.includes(location.pathname.split('/')[2]) ? 'contact' : ''],
    userInfo: {},
    companyList: [],
    userChange: true,
    type: 'Hello',
    switchListInfo: [{ name: '' }],
    currentSelectKey: -1,
    dynamicsInfo: {
      unread_count: 0,
      data: [
        {
          type: '评审通小秘书',
          unread_count: 0,
          data: {
            title: '我是小秘书',
            content: '有什么问题可以找我'
          }
        },
        {
          type: '评审通大秘书',
          unread_count: 0,
          data: {
            title: '我是大秘书',
            content: '有什么问题也可以找我'
          }
        }
      ]
    },
    workmessage: {
      currentPage: 1,
      data: [],
      noMore: false
    }
  },
  effects: {
    /**
     * 查找用户信息
     * @param ____  payload 用户传过来的参数
     * @param param1 sagaEffects方法
     */
    *queryUserInfo(____, { call, put }) {
      yield put({ type: 'setUserInfo', payload: yield call(req, "/corpprateInfo") });
      yield put({ type: 'setCompanyList', payload: yield call(req, "/companyList") });
    },
    *quqeryUpdate({ payload: { params, cb } }, { call, put }) {
      const reslut = yield call(req, '/api/dynamic_update', {
        method: 'POST',
        body: params
      });
      yield put({
        type: 'setType',
        payload: reslut
      })
      cb && cb(reslut);
    },
    /** 查找最新应用动态信息 */
    *queryDynamicsInfo(__, { call, put }) {
      const dynamicInfo = yield call(req, `/api/dynamic_get_list_info`);
      let allArr = [];
      if (dynamicInfo.status == 'success') {
        for (var i = 0; i < dynamicInfo.data.length; i++) {
          var flag = true;
          for (var j = 0; j < allArr.length; j++) {
            if (dynamicInfo.data[i].type == allArr[j].type || dynamicInfo.data[i].type == 'entrust_user' || dynamicInfo.data[i].type == 'invite_partner') {
              flag = false;
            };
          };
          if (flag) {
            allArr.push(dynamicInfo.data[i]);
          };
        };
      }
      let pst = dynamicInfo.data.filter((pro: any) => {
        return pro.type == 'c_pst'
      })
      let notice = dynamicInfo.data.filter((pro: any) => {
        return pro.type == 'c_notice'
      })
      let approval = dynamicInfo.data.filter((pro: any) => {
        return pro.type == 'c_approval'
      })
      let collaborative = dynamicInfo.data.filter((pro: any) => {
        return pro.type == 'c_collaborative'
      })
      // let web_notice=dynamicInfo.data.filter((pro:any)=>{
      //   return pro.type=='web_notice'
      // })
      let numberpst = 0;
      pst.forEach((pro: any) => {
        return numberpst += pro.unread_count
      })
      //邀请信息四个分类叠加
      let numberweb_notice = 0
      dynamicInfo.data.forEach((pro: any) => {
        if (pro.type != 'c_pst' && pro.type != 'c_notice' && pro.type != 'c_approval' && pro.type != 'c_collaborative') {
          return numberweb_notice += pro.unread_count
        }
      })
      let numbernotice = 0;
      notice.forEach((pro: any) => {
        return numbernotice += pro.unread_count
      })
      let numberapproval = 0;
      approval.forEach((pro: any) => {
        return numberapproval += pro.unread_count
      })
      //  let numberweb_notice=0;
      //  web_notice.forEach((pro:any)=>{
      //    return numberweb_notice+=pro.unread_count
      //  })
      let numbercollaborative = 0;
      collaborative.forEach((pro: any) => {
        return numbercollaborative += pro.unread_count
      })
      for (let i = 0; i < allArr.length; i++) {
        if (allArr[i].type == 'c_pst') {
          allArr[i].unread_count = numberpst;
        } else if (allArr[i].type == 'c_notice') {
          allArr[i].unread_count = numbernotice;
        }
        else if (allArr[i].type == 'c_approval') {
          allArr[i].unread_count = numberapproval;
        }
        else if (allArr[i].type == 'c_collaborative') {
          allArr[i].unread_count = numbercollaborative;
        }
        else if (allArr[i].type == 'web_notice') {
          allArr[i].unread_count = numberweb_notice;
        }
      }
      dynamicInfo.data = allArr;
      dynamicInfo.numberweb_notice = numberweb_notice;
      yield put({
        type: 'setDynamicsInfo',
        payload: dynamicInfo
      });
      yield put({
        type: 'Notification/setDynamicsCount',
        payload: {
          unreadCount: dynamicInfo.unread_count,
          numberweb_notice: dynamicInfo.numberweb_notice,
        }
      });
    },
    /**
     * 删除某条动态信息
     * @param payload 当前需要删除的动态的type, 唯一id(比如工作通知是company_id)
     */
    *deleteListNode({ payload }, { call, put }, ) {
      // 唯一标识映射
      const uniqueIDMap = {
        work_dynamic: 'company_id',
        群组聊天: '群组_id',
        单人聊天: 'user_id'
      }
      // 动态传id
      const deleteInfo = {
        type: payload.type,
        [uniqueIDMap[payload.type]]: payload.data[uniqueIDMap[payload.type]],
        // company_id: payload.data.company_id
      };
      // 请求服务端删除
      const result = yield call(req, `/api/deleteNotifyByType`, {
        // const result = yield call(req, `/api/dynamic_delete_list_node`, {
        method: 'POST',
        body: deleteInfo
      });
      // 如果成功, 刷新数据
      if (result.status === 'success') {
        window.location.reload()
        // yield put({
        //   type: 'queryDynamicsInfo'
        // })
      }
    },
    /** 显示某一类动态详情信息 */
    *queryDynamicDetail({ payload: { params, cb } }, { call, put }) {

      const result = yield call(get, `/api/dynamic_get_list_detail`, {
        params
      });
    
      if (result.status === 'success') {
        // let read = result.data.filter((pro: any) => {
        //   return pro.data.readed == 0;
        // })
        // let unread = result.data.filter((pro: any) => {
        //   return pro.data.readed == 1;
        // })
        // result.data = [...read, ...unread];
        yield put({
          type: 'setDynamicDetail',
          payload: {
            type: '',
            currentPage: params.page,
            data: result.data,
			      count:result.count
          }
        })
        cb && cb(result);
      } else if (result.status === 'fail'&&result.massage=='没有消息') {
        // message.info('没有更多消息了');
        yield put({
          type: 'setDynamicDetail',
          payload: {
            type: 'noMore'
          }
        })
        cb && cb(result);
      }
    },
    /** 查找工作台显示的模块列表 */
    *querySwitchListInfo(____, { call, put }) {
      yield put({
        type: 'setSwitchListInfo',
        payload: yield call(req, `/switchListInfo`)
      });
    },
  },
  reducers: {

    /** 设置欢迎信息 */
    setHello(state) {
      const nowTimeSection = parseInt(moment().format('HH'), 10);
      let helloInfo: string[] = [];
      for (const [time, text] of helloTime) {
        if (time.includes(nowTimeSection)) {
          helloInfo = text;
          break;
        }
      }
      return {
        ...state,
        helloInfo
      }
    },
    /**
     * 设置用户信息到state
     * @param state 上面的state
     * @param param1 payload 用户传过来的参数
     */
    setType(state, { payload: Type }: any) {
      return {
        ...state,
        Type
      }
    },
    setUserInfo(state, { payload: newCard }: any) {
      state.userChange = false;
      return {
        ...state,
        userInfo: newCard
      }
    },
    /** 设置用户当前拥有的公司列表信息 */
    setCompanyList(state, { payload: newCard }: any) {

      state.userChange = false;
      return {
        ...state,
        companyList: newCard
      }
    },
    /**
     * 设置最新应用动态信息
     * @param payload 用户传过来的参数,应用名称,比如'评审通' 或 '全部动态'
     */
    setDynamicsInfo(state, { payload: newCard }: any) {
      return {
        ...state,
        dynamicsInfo: newCard
      }
    },
    updateDynamicsInfo(state, { payload: { data, action } }: any) {

      const newDynamicsInfo = state.dynamicsInfo.data;
      let currentSelectKey = state.currentSelectKey; // 动态列表当前选中key
      let newDynamic = true;
      let prevUnreadCount: number = 0; // 初始化当前通知的未读数量
      newDynamicsInfo.forEach((item: any, k: number) => {
        if (item.type === data.type) {
          if (item.type === 'c_collaborative') {
            if (item.data.company_id === data.data.company_id) {
              if (action === 'read') {
                data.unread_count = 0;
              } else {
                data.unread_count = prevUnreadCount = item.unread_count + 1;
              }
              newDynamicsInfo[k] = data;
              newDynamic = false;
            }
          } else if (item.type === 'c_notice') {
            if (item.data.company_id === data.data.company_id) {
              if (action === 'read') {
                data.unread_count = 0;
              } else {
                data.unread_count = prevUnreadCount = item.unread_count + 1;
              }
              newDynamicsInfo[k] = data;
              newDynamic = false;
            }
          } else if (item.type === 'c_approval') {
            if (item.data.company_id === data.data.company_id) {
              if (action === 'read') {
                data.unread_count = 0;
              } else {
                data.unread_count = prevUnreadCount = item.unread_count + 1;
              }
              newDynamicsInfo[k] = data;
              newDynamic = false;
            }
          } else if (item.type === 'c_pst') {
            if (item.data.company_id === data.data.company_id) {
              if (action === 'read') {
                data.unread_count = 0;
              } else {
                data.unread_count = prevUnreadCount = item.unread_count + 1;
              }
              newDynamicsInfo[k] = data;
              newDynamic = false;
            }
          } else if (item.type === 'web_notice') {
            if (item.data.company_id === data.data.company_id) {
              if (action === 'read') {
                data.unread_count = 0;
              } else {
                data.unread_count = prevUnreadCount = item.unread_count + 1;
              }
              newDynamicsInfo[k] = data;
              newDynamic = false;
            }
            newDynamicsInfo[k] = data;
            newDynamic = false;
          } else if (item.type === '群组聊天') {
            if (item.data.群组_id === data.data.群组_id) {
              if (action === 'read') {
                data.unread_count = 0;
              } else {
                data.unread_count = prevUnreadCount = item.unread_count + 1;
              }
              newDynamicsInfo[k] = data;
              newDynamic = false;
            }
            newDynamicsInfo[k] = data;
            newDynamic = false;
          } else if (item.type === '单人聊天') {
            if (item.data.user_id === data.data.user_id) {
              if (action === 'read') {
                data.unread_count = 0;
              } else {
                data.unread_count = prevUnreadCount = item.unread_count + 1;
              }
              newDynamicsInfo[k] = data;
              newDynamic = false;
            }
          }
        }
      });
      if (newDynamic) {
        newDynamicsInfo.unshift(data);
        if (state.type !== 'Hello') {
          currentSelectKey = state.currentSelectKey + 1
        }
      }
      return {
        ...state,
        dynamicsInfo: {
          unread_count: action === 'read' ?
            state.dynamicsInfo.unread_count - prevUnreadCount :
            action === 'news' ?
              state.dynamicsInfo.unread_count + 1 :
              state.dynamicsInfo.unread_count,
          data: [...newDynamicsInfo]
        },
        latestDynamic: data,
        currentSelectKey
      }
    },
    /** 设置当前动态左侧选中项,以及类型 */
    setCurrentSelectKey(state, { payload }: any) {
      const { type, currentSelectKey } = payload
      return {
        ...state,
        type,
        currentSelectKey
      }
    },
    /**
     * 设置某一类动态详细信息
     * @param payload 用户传过来的参数,应用名称,比如'评审通' 或 '全部动态'
     */
    setDynamicDetail(state, { payload: newCard }: any) {
      if (newCard.type === 'noMore') { // 没有更多
        return {
          ...state,
          workmessage: {
            noMore: true,
            currentPage: state.workmessage.currentPage,
            data: state.workmessage.data,
			      count:0
          }
        }
      }
	  //  else if (state.workmessage.currentPage === newCard.currentPage) { // 刷新
   //      return {
   //        ...state,
   //        workmessage: {
   //          noMore: true,
   //          currentPage: state.workmessage.currentPage,
   //          data: [...newCard.data],
			// count:newCard.count
   //        }
   //      }
   //    } 
	  else { // 更新数据
        return {
          ...state,
          workmessage: {
		    noMore: false,
            currentPage: newCard.currentPage,
            data: [...newCard.data],
			count:newCard.count
          }
        }
      }
    },
    /** 设置工作台显示的模块列表信息 */
    setSwitchListInfo(state, { payload: newCard }: any) {
      return {
        ...state,
        switchListInfo: newCard
      }
    },
    changeUser(state, { payload: newCard }: any) {
      state.userChange = true;
      return state
    },
    setTimer(state, { payload: newCard }: any) {
      state.timers = newCard;
      return state
    }
  }
}
export default Workdynamics