import { Model } from "dva";
import request, { get } from '../utils/request';
import download from '../utils/download';
import { message } from 'antd';
const Report: Model = {
    namespace: 'Report',
    state: {

    },
    effects: {
        *querygetReplacedVar({ payload: { params, cb } }, { call, put }) {
            const result = yield call(get, '/api/c_pst_getReplacedVarTemplate', {
                params
            })
            yield put({
                type: 'setReplacedVar',
                payload: result.data
            });
            cb && cb(result.data)
        },
        *queryApprovalgetReplacedVar({ payload: { params, cb } }, { call, put }) {
            const result = yield call(get, '/api/c_approval_getReplacedVarTemplate', {
                params
            })
            yield put({
                type: 'setApprovalReplacedVar',
                payload: result.data
            });
            cb && cb(result.data)
        },
        *queryexportSingle({ payload: { params, cb } }, { call, put }) {
            const result = yield call(request, '/api/c_pst_exportSingleTemplatePackage', {
                method: 'POST',
                body: params,
                getFile: true,
            })
            yield put({
                type: 'setexportSingle',
                payload: result
            });
            if (result.blob) {
                download(result.blob, decodeURI(result.headers.get('filename')));
                message.info('导出成功');
            }
            cb && cb(result)
        },
        *queryApprovalexportSingle({ payload: { params, cb } }, { call, put }) {
            const result = yield call(request, '/api/c_approval_exportSingleTemplatePackage', {
                method: 'POST',
                body: params,
                getFile: true,
            })
            yield put({
                type: 'setApprovalexportSingle',
                payload: result
            });
            if (result.blob) {
                download(result.blob, decodeURI(result.headers.get('filename')));
                message.info('导出成功');
            }
            cb && cb(result)
        },
        *queryexportRecordSingle({ payload: { params, cb } }, { call, put }) {
            const result = yield call(request, '/api/c_pst_exportRecordSingleTemplatePackage', {
                method: 'POST',
                body: params,
                getFile: true,
            })
            yield put({
                type: 'setexportRecordSingle',
                payload: result
            });
            if (result.blob) {
                download(result.blob, decodeURI(result.headers.get('filename')));
                message.info('导出成功');
            }
            cb && cb(result)
        },
        *queryTemplatePackage({ payload: { params, cb } }, { call, put }) {
            const result = yield call(request, '/api/c_pst_exportTemplatePackage', {
                method: 'POST',
                body: params,
                getFile: true,
            })
            yield put({
                type: 'setexportSingle',
                payload: result
            });
            if (result.blob) {
                download(result.blob, decodeURI(result.headers.get('filename')));
                message.info('导出成功');
            }
            cb && cb(result)
        },

        *queryApprovalTemplatePackage({ payload: { params, cb } }, { call, put }) {
            const result = yield call(request, '/api/c_approval_exportTemplatePackage', {
                method: 'POST',
                body: params,
                getFile: true,
            })
            yield put({
                type: 'setApprovalexportSingle',
                payload: result
            });
            if (result.blob) {
                download(result.blob, decodeURI(result.headers.get('filename')));
                message.info('导出成功');
            }
            cb && cb(result)
        },
        // /api/c_pst_exportTemplatePackage

        *querysaveWordTemplate({ payload: { params, cb } }, { call, put }) {
            const result = yield call(request, '/api/c_pst_saveWordTemplate', {
                method: 'POST',
                body: params
            })
            yield put({
                type: 'setsaveWordTemplate',
                payload: result
            });
            cb && cb(result)
        },
        *queryApprovalsaveWordTemplate({ payload: { params, cb } }, { call, put }) {
            const result = yield call(request, '/api/c_approval_saveWordTemplate', {
                method: 'POST',
                body: params
            })
            yield put({
                type: 'setApprovalsaveWordTemplate',
                payload: result
            });
            cb && cb(result)
        },
        *queryClassicThesaved({ payload: { params, cb } }, { call, put }) {
            const result = yield get('/api/c_pst_getWordTemplateListByPstId', {
                params
            });
            if (result.status === 'success') {
                cb && cb(result.data);
                yield put({ 
                    type: 'setClassicThesaved', 
                    payload: result.data 
                });
            } else {
                message.error('服务器异常，请稍后再试')
            }
        },

        *queryApprovalClassicThesaved({ payload: { params, cb } }, { call, put }) {
            const result = yield get('/api/c_approval_getWordTemplateListByApprovalId', {
                params
            });
            if (result.status === 'success') {
                cb && cb(result.data);
                yield put({ 
                    type: 'setApprovalClassicThesaved', 
                    payload: result.data 
                });
            } else {
                message.error('服务器异常，请稍后再试')
            }
        },

    },
    reducers: {
        setReplacedVar(state, { payload: Report }: any) {
            return {
                ...state,
                Report
            }
        },
        setApprovalReplacedVar(state, { payload: Report }: any) {
            return {
                ...state,
                Report
            }
        },
        setexportSingle(state, { payload: exportSingle }: any) {
            return {
                ...state,
                exportSingle
            }
        },
        setApprovalexportSingle(state, { payload: exportSingle }: any) {
            return {
                ...state,
                exportSingle
            }
        },
        setexportRecordSingle(state, { payload: exportRecordSingle }: any) {
            return {
                ...state,
                exportRecordSingle
            }
        },
        setsaveWordTemplate(state, { payload: saveWordTemplate }: any) {
            return {
                ...state,
                saveWordTemplate
            }
        },
        setApprovalsaveWordTemplate(state, { payload: saveWordTemplate }: any) {
            return {
                ...state,
                saveWordTemplate
            }
        },
        setClassicThesaved(state, { payload }: any) {
            return {
                ...state,
                classicTheSaved: payload
            }
        },
        setApprovalClassicThesaved(state, { payload }: any) {
            return {
                ...state,
                classicTheSaved: payload
            }
        },
    },

}
export default Report