import React from 'react';
import { Layout, Menu, Dropdown, Icon, Avatar, Badge, notification } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'dva';
import Debounce from 'lodash-decorators/debounce';
//  import { Location} from 'history';
import { get } from '../utils/request';
import ModalCompent from '../components/ModalCompent';
import PersonalCardModal from './personalCard';
import './header.scss';
import logo from './guide/1.png';
import logos from './guide/2.png';
// import logojianhao from './guide/减号 (5).png';
// import logouts from './guide/weixin.jpg';
// import logozaixian from './guide/在线客服.png';
// import logoQQ from './guide/QQke.png';
// import logokefu from './guide/kefu.png';
import StringInterception from '../utils/stringInterception';
import { showhidden } from '../utils/getAuthorization';
// import logokefu from './guide/kefu.svg';
// import logoguide from './guide/kefutu.png';
// import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import './index.scss';
const { Header } = Layout;

const NOTIFICATION = 'Notification';
const USERINFO = 'UserInfo';

const mapStateToProps = (state: any) => {
  return {
    ...state[NOTIFICATION],
    permission:state[USERINFO].permission,
    userInfo: state[USERINFO].userInfo
  }
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    showDynamicsCount: () => {
      dispatch({
        type: `${NOTIFICATION}/queryDynamicsCount`
      })
    },
    setDynamicsInfo: (dynamicsInfo: string) => {
      dispatch({
        type: `${NOTIFICATION}/setDynamicsInfo`,
        payload: dynamicsInfo
      })
    },
    setDynamicsCount: (unreadCount: number, action: string) => {
      dispatch({
        type: `${NOTIFICATION}/setDynamicsCount`,
        payload: {
          unreadCount,
          action
        }
      })
    },
    /** 单独获取未读动态消息数量 */
    queryDynamicsCount: (cb: any) => {
      dispatch({
        type: `${NOTIFICATION}/queryDynamicsCount`,
        payload: {
          cb
        }
      });
    },
    queryUserInfo: (cb: Function) => {
      dispatch({
        type: `${USERINFO}/queryUserInfo`,
        payload: { cb }
      })
    },
    quqeryUpdate: (params: any, cb: Function) => {
      dispatch({
        type: `${'Workdynamics'}/quqeryUpdate`,
        payload: { params, cb }
      })
    },
    queryUserPermission: (cb: Function) => {
      dispatch({
        type: `${USERINFO}/queryUserPermission`,
        payload: { cb }
      })
    }
  }
}
/** 退出登录 */
function logout() {
  window.localStorage.removeItem('access_token');
  window.localStorage.removeItem('refresh_token');
  window.location.href = '/login';
}
@connect(mapStateToProps, mapDispatchToProps)
export default class AppHeader extends React.Component<any>{
  state = {
    url: window.location.pathname === '/' ? "dynamic" : window.location.pathname.split('/')[1],
    icon: 'down-circle',
    searchValue: '',
    isFocus: false,
    businessCardVisible: false,
    cardInfo: null,
    unreadCount: 0,
    numberweb_notice: 0,
    hidden: true,
    current_company: [] as any,
    visibles: true,
    visible: true,
    number: 0,
    onCancelvisible: false,
    show: true,
    hiddenshow: false,
    showhiddens: true,
    toolbarItemVisiable: false,
    pstbadgenum:0,
    spbadgenum:0,
    xzbadgenum:0,
    xtbadgenum:0,
    worknum:0,
    waitreceive:''
  }
  hideMenu = () => {
    this.setState({
      visibles: true
    })
    localStorage.setItem('hiddens', '1')
  }
  //新建公司列表接口
  comlist = async () => {
    const result = await get('/api/c_company_list');
    if (result.current_company) {
      localStorage.removeItem('Divider')
      this.setState({
        current_company: result.current_company
      })
    }
  }
  //邀请信息里面四个分类数据叠加的请求
  update = async () => {
    const result = await get('/api/u_get_invitelist')
    let numberweb_notice = 0
    if (result.status == 'success') {
      let { externalContactRecord, inviteOperateRecord } = result.data;
      numberweb_notice = externalContactRecord + inviteOperateRecord
      // result.data.forEach((pro: any) => {
      //   if (pro.type != 'c_pst' && pro.type != 'c_notice' && pro.type != 'c_approval' && pro.type != 'c_collaborative') {
      //     return numberweb_notice += pro.unread_count
      //   }
      // })
      localStorage.setItem('numberweb_notice', [numberweb_notice].join(''))
      this.setState({
        numberweb_notice
      })
    }
  }
  componentDidUpdate(prev: any) {
    if (showhidden) {
      if (localStorage.getItem('Divider') == '1') {
        this.props.quqeryUpdate(null, (reslut: any) => {
          this.props.queryDynamicsCount((reslut: any) => {
          });
          localStorage.removeItem('Divider')
          // this.Request()
          // this.spRequest()
          // this.xzRequest()
          setTimeout(() => {
            this.Request()
            this.spRequest()
            this.xzRequest()
            this.xtRuquest()
          }, 2000);
        })
        this.comlist();
        this.update();
      }
      if (prev.unreadCount != this.props.unreadCount) {
        this.update();
      }
      if (localStorage.getItem('fileResponse') == '1') {
        this.props.queryUserInfo();
        localStorage.removeItem('fileResponse')
      }
    
    }
      // 全部已读更新动态 badge

      setTimeout(()=>{
        if(localStorage.getItem('updatedynamic')=='1'){
          this.props.queryDynamicsCount();
          localStorage.removeItem('updatedynamic')
        }
      },2000)

  }
  HanleScorll = () => {

  }
  componentDidMount() {
    // const videos:any=document.getElementById('music')
    // videos.play()
    
    setTimeout(() => {
      this.Request()
      this.spRequest()
      this.xzRequest()
      this.xtRuquest()
    }, 2000);
   
  
    //浏览器检测
    localStorage.setItem('none', 'none')
    // 查询当前未读消息数量
    document.addEventListener('click', this.hideMenu);
    document.addEventListener('scroll', this.HanleScorll)
    if (showhidden) {
      this.comlist();
      this.props.quqeryUpdate(null, (reslut: any) => {
        this.props.queryDynamicsCount();
      })
      this.update();
      let socket: WebSocket | false;
      //获取用户基础信息
      this.props.queryUserInfo((data: any) => {
        if (process.env.NODE_ENV === 'development') {// local
          try {
            socket = new WebSocket(`ws://pst.gzt.test:9501/?user_id=${data.id}`);
          } catch (e) {
            socket = false;
            alert('请配置本地swoole环境');
          }
        } else {
          socket = new WebSocket(`wss://pst.pingshentong.com:9501/wss?user_id=${data.id}`);
        }

        if (socket) {// 如果websocket连接建立成功
          // Connection opened
          socket.addEventListener('open', (event) => {
            socket && socket.send('Hello Server!');
          });
          // Listen for messages
          socket.addEventListener('message', (event) => {
            if(event.data=='new_state' || event.data=='new_state_assist' || event.data=='new_state_approve'){
              return;
            }else if(event.data=='new_state_bar'){
              setTimeout(() => {
                this.Request()
                this.spRequest()
                this.xzRequest()
                this.xtRuquest()
              }, 2000);
            }else{
              const notificationInfo = JSON.parse(event.data);
              if (notificationInfo.type === 'dynamic_single') {// 动态
                notification.open({
                  message: notificationInfo.data.data.title,
                  description: notificationInfo.data.data.content
                });
                this.props.setDynamicsInfo({ data: notificationInfo.data, action: 'news' });
              } else if (notificationInfo.type === 'dynamic_refresh') {
                // 更新当前未读消息数量
                this.props.queryDynamicsCount();
              }
            }
          });
        }
      })
      // 获取当前用户所拥有的权限    
      this.props.queryUserPermission();

    
    }

    //

   
    const gzbadge=this.state.pstbadgenum+this.state.spbadgenum+this.state.xzbadgenum
    setTimeout(()=>{
       this.setState({
        worknum: gzbadge
       })
    },10)

    // console.log(this.state.pstbadgenum,'this.state.pstbadgenum')


  }
  
  Request = async () => {
    const reslut = await get('/api/c_pst_get_pst_state')
    // const badgenum:any=reslut.wait_receive+reslut.wait_appoint+reslut.wait_approval+reslut.backed

    // setTimeout(()=>{
    //    this.setState({
    //     pstbadgenum: badgenum,
    //     worknum: badgenum+this.state.spbadgenum+this.state.xzbadgenum+this.state.xtbadgenum
    //    })
    // },0)
    // var PERMISSION_RECIVE;
    var badgenum;
    setTimeout(()=>{
      // PERMISSION_RECIVE = this.props.permission &&  this.props.permission.includes('c_pst_recive_per');
      //   console.log(PERMISSION_RECIVE,'PERMISSION_RECIVE')
        // if(PERMISSION_RECIVE){
        //     badgenum=reslut.wait_receive+reslut.wait_appoint+reslut.wait_approval+reslut.backed+reslut.needMeJudge
        //     this.setState({
        //       pstbadgenum: badgenum,
        //       worknum: badgenum+this.state.spbadgenum+this.state.xzbadgenum+this.state.xtbadgenum
        //     })
        // }else{
          badgenum=reslut.wait_appoint+reslut.wait_approval+reslut.backed+reslut.needMeJudge+reslut.wait_receive
          this.setState({
            pstbadgenum: badgenum,
            worknum: badgenum+this.state.spbadgenum+this.state.xzbadgenum+this.state.xtbadgenum
          })
        // }
    },1000)
    // console.log(badgenum,'badgenum')
  }
  // 审批
  spRequest = async () => {
    const reslut = await get('/api/c_approval_get_state')
    this.setState({
      spbadgenum: reslut.pending,
      worknum: this.state.pstbadgenum+reslut.pending+this.state.xzbadgenum+this.state.xtbadgenum
    })
  }
  xzRequest = async () => {
    
    // $api->get('/c_assist_get_task_state','CollaborationController@getTaskState');  //协助左侧气泡
    const reslut = await get('/api/c_assist_get_task_state')
    const badgenum:any=reslut.received+reslut.review
    this.setState({
      xzbadgenum: badgenum,
      worknum: this.state.pstbadgenum+badgenum+this.state.spbadgenum+this.state.xtbadgenum
    })
  }
  xtRuquest = async () => {
    // const { permission} = this.props;
    const promission=localStorage.getItem('user_permission')
    // console.log(promission,'promission')
   
    if(promission=='true'){
      const result = await get('/api/u_get_invitelist');
      const xtnum:any=result.data.companyEntrustRecord+result.data.companyPartnerRecord
      // console.log(xtnum,'xtnum')

      this.setState({
        xtbadgenum: xtnum,
        worknum: this.state.pstbadgenum+this.state.xzbadgenum+this.state.spbadgenum+xtnum
      })
    }else{
      this.setState({
        worknum: this.state.pstbadgenum+this.state.xzbadgenum+this.state.spbadgenum
      })
    }
    // const PERMISSION_RECIVE = this.props.permission &&  this.props.permission.includes('c_pst_recive_per');
    // console.log(PERMISSION_RECIVE,'PERMISSION_RECIVE')
  }
  // workFun = () =>{
  //   const videos:any=document.getElementById('music')
  //   videos.play()
  // }
  emitEmpty = () => {
    this.setState({ searchValue: '' });
    this.setState({ isFocus: false });
  }
  onFocus = () => {
    this.setState({ isFocus: true });
  }
  onChangeSearchValue = (e: any) => {
    this.setState({ searchValue: e.target.value });
    this.debounceSearchValue();

  }
  showBusinessCard = async () => {
    this.setState({
      businessCardVisible: true
    })
    const result = await get('/api/u_get_card_info');
    if (result.status === 'success') {
      this.setState({
        cardInfo: result.data
      })
    }
  }
  hiddenBusinessCard = () => {
    this.setState({
      businessCardVisible: false
    })
  }
  @Debounce(500, {
    leading: true,
    trailing: false,
  })
  debounceSearchValue() {
    // 像后台发送search请求
  }
  judage = (body: string) => {
    let res = true;
    if (body.indexOf('/work/review/detail') == -1) {
      res = true
    } else {
      res = false;
    }
    return res
  }
  render() {
    const { userInfo, unreadCount} = this.props;
    const { isFocus, cardInfo, numberweb_notice, hidden, current_company, visibles, visible, onCancelvisible,worknum } = this.state;
    const display = isFocus ? 'none' : 'inline-block';
    // const worknums=this.state.pstbadgenum+this.state.xzbadgenum+this.state.spbadgenum
    // console.log(worknums,'worknum')


    /** 设置菜单 */
    const settings = (
      <Menu>
        {/* 个人信息气泡 */}
        <Menu.Item key="profile" onClick={() => {
          this.setState({
            hidden: false
          })
        }}>
          <Link to={{
            pathname: '/settings/percenter'
          }}>
            {hidden && numberweb_notice > 0 ?
              <Badge count={numberweb_notice} offset={[10, 0]}>
                <span>个人信息</span>
              </Badge>
              :
              <span>个人信息</span>
            }
          </Link>
        </Menu.Item>
        {/* 
        <Menu.Item key="1">
          <Link to="/systemSettings">系统设置</Link>
        </Menu.Item> */}
        <Menu.Item key="password">
          <a href="/reset" target="_blank">修改密码</a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="companyRegister" onClick={() => {
          this.setState({
            visible: false,
          })
        }}>
          <Link to="/companyRegister">创建组织</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="website">
          <a href="https://www.pingshentong.com">评审通官网</a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={logout}>
          <span>退出登录</span>
        </Menu.Item>
      </Menu>
    );
    return (
      <Header className="header" style={{ display: showhidden ? 'block' : 'none', zIndex: 11111111111 }} onClick={() => {
        localStorage.setItem('headeronClick', '1');
      }} >
        <div style={{ zIndex: 199, position: 'relative', float: 'left', padding: '0 10px', width: '280px' }}>
          <div style={{ marginRight: '15px', display }}>
            <span style={{ padding: '0 10px', color: '#333', cursor: 'pointer' }} onClick={this.showBusinessCard}>
              <Avatar size={42} src={userInfo && userInfo.avatar.status === 'success' && userInfo.avatar.avatar.oss_path} style={{ color: '#f56a00', backgroundColor: '#fde3cf', visibility: localStorage.getItem('none') == 'none' || localStorage.getItem('none') == null ? 'visible' : 'hidden' }}>
                {userInfo &&StringInterception(userInfo.name)}
              </Avatar>
            </span>
          </div>
        </div>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={[this.state.url]}
          style={{ float: 'left', marginLeft: 'calc(50% - 387px)', lineHeight: '60px', color: '#333', textAlign: 'center' }}
          className="header-nav"
        >
          <Menu.Item key="dynamic" onClick={() => {
            if (showhidden) {
              this.props.quqeryUpdate(null, (reslut: any) => {
                this.props.queryDynamicsCount();
              })
            }
          }}>
            <Link style={{ display: 'block' }} to={{pathname:"/dynamic",state:{
               type:'dynamic'
            }}}>
              <Badge count={unreadCount > 0 ? unreadCount : null} style={{ boxShadow: '0 0 0 0px #FF0000' }} offset={[5, 0]}>
                <span style={{ fontSize: '17px' }}>动态</span>
              </Badge>
            </Link>
          </Menu.Item>
          <Menu.Item key="work">
            {/* <Link to="/work">工作</Link> */}
            <Link style={{ display: 'block' }} to="/work">
              <Badge count={worknum?worknum:0} style={{ boxShadow: '0 0 0 0px #FF0000' }} offset={[5, 0]}>
                <span style={{ fontSize: '17px' }} >工作</span>
              </Badge>
            </Link>
          </Menu.Item>
          <Menu.Item key="doc">
            <Link to="/doc">文档</Link>
          </Menu.Item>
          <Menu.Item key="contact">
            <Link to="/contact">联系人</Link>
          </Menu.Item>
        </Menu>
        <div style={{ float: 'right', paddingRight: '16px', textAlign: 'right', position: 'fixed', right: 0 }}>
          {current_company.length != 0 ? null : visibles ? localStorage.getItem('hidden') == null ? <img src={logo} /> : null : null}
          {current_company.length != 0 ? null : visible && !visibles ? localStorage.getItem('hiddens') == null ? <img src={logos} style={{ transform: 'translate(-80px,120px)' }} /> : null : null}

          <Dropdown
            overlay={settings}
            overlayStyle={{ padding: '0 10px' }}
            trigger={['click']}
          >
            <Badge count={numberweb_notice} style={{ boxShadow: '0 0 0 0px 	#FF0000' }}>
              <span style={{ padding: '0 10px', color: '#fff', cursor: 'pointer' }} onClick={(e: any) => {
                localStorage.setItem('hidden', '1')
                this.setState({
                  number: this.state.number + 1
                }, () => {
                  let { number } = this.state;
                  if (number % 2 == 1) {
                    this.setState({
                      visibles: false,
                    })
                  } else {
                    this.setState({
                      visibles: true
                    })
                  }
                })

                e.nativeEvent.stopImmediatePropagation()
              }}>
                <Icon type="appstore" theme="filled" style={{ fontSize: '16px' }} title='点击创建组织' />
              </span>
            </Badge>
          </Dropdown>
          <a href='https://www.pingshentong.com/help' target='_blank' style={{color:'#FFFFFF',fontSize:14}}>帮助</a>
        </div>

        {/*  */}
        <PersonalCardModal
          visible={this.state.businessCardVisible}
          onCancel={this.hiddenBusinessCard}
          dataSource={cardInfo}
        />
        <ModalCompent visible={onCancelvisible} onCancel={() => {
          this.setState({
            onCancelvisible: false
          })
        }} />


        <audio
          id="music"
          style={{height: 0}}
          src="https://psts.oss-cn-beijing.aliyuncs.com/video/2.mp3"
        ></audio>
      </Header>
    );
  }
}