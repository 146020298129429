let $hasLogged = true;
 let $showhidden=false;

let $Authorization: string = 'Unauthenticated';
if (process.env.NODE_ENV === 'development') {// 如果是开发期间
  if (process.env.REACT_APP_ACCESS_TOKEN) {// 前后端分离开发期间
  // console.log(1233)
    $Authorization = `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`
    // $hasLogged=true;
    // $showhidden=true;
    // console.log(process.env)
    if(window.location.pathname=='/help'||window.location.pathname=='/helpgroup'||window.location.pathname=='/helpSerch'){
      $hasLogged=true;
      // $showhidden=false;
      // console.log(localStorage.getItem('access_token'),localStorage.getItem('refresh_token'))
       if(localStorage.getItem('refresh_token')){
        $showhidden=true;
       
       }else{
        // console.log(1233)
        $showhidden=false;
       }
      }else{
        // alert('请手动配置env文件，REACT_APP_ACCESS_TOKEN=你的access_token');
        $hasLogged = true
        $showhidden=true;
      }
  } else {
    $showhidden=false;
    // helpSerch
    if(window.location.pathname=='/help'||window.location.pathname=='/helpgroup'||window.location.pathname=='/helpSerch'){
      $hasLogged=true;
      }else{
        alert('请手动配置env文件，REACT_APP_ACCESS_TOKEN=你的access_token');
        $hasLogged = false
        // $hasLogged = false;
      }
  

  }
} else {// 服务端渲染
  if (localStorage.getItem('access_token')) {
    $Authorization = `Bearer ${localStorage.getItem('access_token')}`
    $showhidden=true;
    $hasLogged=true;
  } else { // 进入页面首次判断是否登录
    $showhidden=false;
    if(window.location.pathname=='/help'||window.location.pathname=='/helpgroup'||window.location.pathname=='/helpSerch'){
      $hasLogged=true;
   
      }else{
        $hasLogged = false;
      }
    // console.log('没有')
   

  }
}

/**
 * 是否登录
 */

const hasLogged = $hasLogged;
// console.log($showhidden)
const Authorization = $Authorization;
const showhidden=$showhidden;
export { hasLogged, Authorization ,showhidden}