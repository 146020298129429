export default function StringInterception(body: string) {
    if (body) {
        if (body.length > 2) {
            if (body.length == 3) {
                body = body.substring(1);
            } else if (body.length == 4) {
                if (body.indexOf('公司') != -1) {
                    let length = body.indexOf('公司');
                    body = body.substring(length - 2, length);
                } else if (body.indexOf('集团') != -1) {
                    let length = body.indexOf('集团');
                    body = body.substring(length - 2, length);
                }
                else if (body.indexOf('科技') != -1) {
                    let length = body.indexOf('科技');
                    body = body.substring(length - 2, length);
                }
                else {
                    body = body.substring(2, 4)
                }
            } else {
                if (body.indexOf('市') != -1) {
                    let length = body.indexOf('市');
                    body = body.substring(length + 1, length + 3)
                } else if (body.indexOf('自治州') != -1) {
                    let length = body.indexOf('自治州');
                    body = body.substring(length + 3, length + 5)
                }
                else if (body.indexOf('省') != -1) {
                    let length = body.indexOf('省');
                    body = body.substring(length + 1, length + 3)
                }
                else if (body.indexOf('自治区') != -1) {
                    let length = body.indexOf('自治区');
                    body = body.substring(length + 3, length + 5)
                }
                else if (StringAllArr(body) == true) {
                    let allArr = ['河北', '山西', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '台湾', '河南', '湖北', '湖南', '广东', '海南', '四川', '贵州', '云南', '陕西', '甘肃', '青海', '新疆', '宁夏', '西藏', '广西', '内蒙古'];
                    let probody = '';
                    for (let i = 0; i < allArr.length; i++) {
                        if (body.indexOf(allArr[i]) != -1) {
                            probody = allArr[i]
                        }
                    }
                    if (probody == '内蒙古') {
                        let length = body.indexOf('内蒙古');
                        body = body.substring(length + 3, length + 5)
                    } else {
                        let length = body.indexOf(probody);
                        body = body.substring(length + 2, length + 4)
                    }

                } else if (body.indexOf('建设') != -1) {
                    let length = body.indexOf('建设');
                    body = body.substring(length - 2, length);
                } else if (body.indexOf('集团') != -1) {
                    let length = body.indexOf('集团');
                    body = body.substring(length - 2, length);
                }
                else if (body.indexOf('科技') != -1) {
                    let length = body.indexOf('科技');
                    body = body.substring(length - 2, length);
                }
                else if (body.indexOf('有限公司') != -1) {
                    let length = body.indexOf('有限公司');
                    body = body.substring(length - 2, length);
                }
                else if (body.indexOf('公司') != -1) {
                    let length = body.indexOf('公司');
                    body = body.substring(length - 2, length);
                } else {
                    body = body.substring(2, 4)
                }
            }
        }
    } else {
        body = ''
    }
    return body
}
function StringAllArr(body: string) {
    let allArr = ['河北', '山西', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '台湾', '河南', '湖北', '湖南', '广东', '海南', '四川', '贵州', '云南', '陕西', '甘肃', '青海', '新疆', '宁夏', '西藏', '广西', '内蒙古'];
    let res = allArr.some((pro: any) => {
        return body.indexOf(pro) != -1
    })
    return res
}