import React, { useState } from 'react';
import { Modal, Button, Input, Upload, Icon, message, Form, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import classnames from 'classnames';
import './index.scss';
import { postForm } from '../../utils/request';
export interface ModalPrint extends FormComponentProps {
  visible: boolean;
  onCancel: any;
}
function ModalPrintModalPrint({ visible, onCancel, form }: ModalPrint) {
  const { getFieldDecorator } = form;
  const [type, setType] = useState('使用问题');
  const [loading, setLoading] = useState(false);
  const beforeUploads = (file: any) => {
    return false;
  }
  const normFiles = (e: any, s: any) => {
    // 限制单个文件
    const isLt20M = e.file.size / 1024 / 1024 < 10;
    const isJpgOrPng = e.file.type === 'image/jpeg' || e.file.type === 'image/png' || e.file.type == 'application/pdf' || e.file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (Array.isArray(e)) {
      return e;
    }
    if ((isLt20M && isJpgOrPng && form.getFieldValue('files').length < 3) || e.file.status === 'removed') {
      return e && e.fileList
    } else {
      if (form.getFieldValue('files').length <= 2) {
        message.error('支持10M以下的图片或者word和pdf文件!');
      } else {
        message.success('最多上传三个文件')
      }
      return e && e.fileList.filter((file: any) => file.uid !== e.file.uid);
    }
  }
  const hanbSumit = () => {
    form.validateFieldsAndScroll(async (err: boolean, values: any) => {
      if (!err) {
        //在此提交反馈意见
        setLoading(true);
        delete values.type;//此type值不是传后台的数据
        const reslut = await postForm('/api/submit_message', {
          method: "POST",
          body: {
            type,
            ...values,
          },
          // getFire:true
        })
        if (reslut.status == 'success') {
          setLoading(false);
          form.resetFields();
          form.setFieldsValue({
            type:'使用问题'
          })
          setType('使用问题')
          message.success('感谢您提出宝贵意见我们会尽快处理');
          onCancel();
        }
      }
    })
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 8,
      },
      sm: {
        span: 16,
        offset: 6,
      },
    }
  }
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title='我们珍惜您的每一条反馈'
      footer={null}
      wrapClassName='footerwrap'
    >
      <p style={{ paddingLeft: '50px' }}>温馨提示: 功能新增,咨询服务,投诉意见,请提交意见或者联系在线客服 及时反馈快速处理</p>
      <Form {...formItemLayout} style={{ width: '700px', paddingRight: '300px', textAlign: 'left' }} onSubmit={(e: any) => {
        e.preventDefault();
        hanbSumit()
      }}>
        <Form.Item
          {...formItemLayout}
          label="请提交类型:"
        >
          {getFieldDecorator('type', {
            //  valuePropName: 'checked',
            initialValue:type,
            rules: [{
              required: true, message: '请选择类型',
            }]
          })(<Radio.Group style={{ display: 'flex' }}>
            <div onClick={() => {
              setType('使用问题')
            }} className={classnames("gutter-box", type === '使用问题' ? "active" : "")}>
              使用问题
                      </div>
            <div style={{ marginLeft: '20px' }} onClick={() => {
              setType('产品功能问题')
            }} className={classnames("gutter-box", type === '产品功能问题' ? "active" : "")}>
              产品功能问题
                      </div>
          </Radio.Group>

          )}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label="内容:"
        >
          {getFieldDecorator('contents', {
            rules: [{
              required: true, message: '请输入内容',
            }
            ],
          })(<Input.TextArea style={{ width: '400px', height: '150px' }} placeholder='请输入内容' />

          )}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label="联系方式">
          {getFieldDecorator('contact', {
            rules: [
              {
                validator: (rule, value, callback) => {
                  let telReg = /^1[3456789][0-9]{9}$/;
                  let QQReg = /^[1-9][0-9]{5,10}$/;
                  let emailReg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                  if (telReg.test(value) || emailReg.test(value) || QQReg.test(value)||value==undefined||value=='') {
                    return true
                  } else {
                    return false
                  }
                  callback()
                }, message: '请输入正确的手机号或者邮箱或者QQ号'
              }
            ],
          })(<Input placeholder="请输入手机号或者邮箱或者QQ号" maxLength={32} />)}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label="上传附件"
          extra="支持.png,.jpeg,pdf,word等类型文件，5M以内"
        >
          {getFieldDecorator('files', {
            valuePropName: 'fileList',
            getValueFromEvent: normFiles,
            initialValue: [],
          })(
            <Upload
              name="files"
              listType="picture"
              multiple={true}
              beforeUpload={beforeUploads}
            >
              <Button>
                <Icon type="upload" />上传附件
                    </Button>

            </Upload>
          )}
        </Form.Item>
        <Form.Item
          {...tailFormItemLayout}

        >
          <Button style={{ marginLeft: '30px' }} htmlType='submit' loading={loading} type='primary'>提交反馈</Button>
          <Button style={{ marginLeft: '15px' }} onClick={() => {
            onCancel()
          }}>取消</Button>
        </Form.Item>
      </Form>


    </Modal>
  )
}
export default Form.create<ModalPrint>()(ModalPrintModalPrint)