import React,{useEffect} from 'react';
import imgage from "../assets/images/header/360.png";
import chrome from "../assets/images/header/Chrome.png";
import  "./index.scss";
function Brower(props:any){
  // console.log(props)
  useEffect(()=>{
    if(props.match.path == '/brower'){
      localStorage.setItem('none','block')
    }else{
      localStorage.setItem('none','none')
    }
  },[props])
  
return(
    <div className='div'>
      <div style={{marginLeft:'250px',marginTop:'300px',fontSize:'22px',color:'#fff'}}>
        抱歉、系统检测到您用了IE或者低版本浏览器
      </div>
      <div style={{marginLeft:'250px',marginTop:'30px',fontSize:'22px',color:'#fff'}}>
        为了更好的体验评审通，推荐用最新的浏览器 
      </div>
      <div style={{marginLeft:'250px',fontSize:'22px',color:'#fff'}}>
        您也可以通过下方按钮进行下载合适的浏览器
      </div>
      <a href="https://www.google.cn/intl/zh-CN/chrome/" target="view_window">
        <img src={chrome} style={{marginLeft:'200px',marginTop:'100px'}}/>
      </a>
      <a href="https://browser.360.cn" target="view_window">
        <img src={imgage} style={{marginLeft:'50px',marginTop:'100px'}}/>
      </a>   
    </div>
  )
}
export default Brower