/**
 * 下载文件
 * @param blob 二进制大对象
 * @param filename 文件名
 */
export default function download(blob: Blob, filename: string) {
    console.log(typeof(blob),'999999999999123')
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = filename; // 文件名
    a.href = blobUrl;
    a.click();
    window.URL.revokeObjectURL(blobUrl);
}