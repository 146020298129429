import React from 'react';
import { Tooltip} from 'antd';
import ModalCompent from '../components/ModalCompent';
// import { showhidden } from '../utils/getAuthorization';
// import logojianhao from './guide/减号 (5).png';
import logouts from './guide/weixin.jpg';
// import logozaixian from './guide/在线客服.png';
// import logoQQ from './guide/QQke.png';
import logoguide from './guide/更多.png';
import kefuweixin from './guide/Customer/微信.svg';
import kefuweixins from './guide/Customer/微信 (2).svg';
import kefulogo from './guide/Customer/客服 (3).svg';
import kefulogos from './guide/Customer/客服 (4).svg';
import kefuyijian from './guide/Customer/意见反馈 (1).svg';
import kefuyijians from './guide/Customer/意见反馈 (2).svg';
import kefushang from './guide/Customer/置顶 (1).svg';
import kefushangs from './guide/Customer/置顶 (2).svg';
// import kefuphone from './guide/电话.svg';
// import kefufankui from './guide/反馈问题.svg';
// import kefushang from './guide/上.svg';

import 'intro.js/introjs.css';
import './index.scss';
export default class AppHeader extends React.Component<any>{
    state = {
        hiddenshow: false,
        kefulogosVisiable:false,
        toolbarItemVisiable: false,
        onCancelvisible: false,
        kefuweixinsVisiable:false,
        toolbarItemVisiableShow: false,
        kefuyijiansVisiable:false,
        kefushangVisiable:false
    }
    judage = (body: string) => {
        let res = true;
        if (body.indexOf('/work/review/detail') == -1) {
            res = true
        } else {
            res = false;
        }
        return res
    }

    render() {
        const { hiddenshow, onCancelvisible ,kefulogosVisiable,kefuweixinsVisiable,kefuyijiansVisiable,kefushangVisiable} = this.state;
        return (
            <div style={{ zIndex: 999}} id='topAnchor' className='CustomerService'>
                {hiddenshow? null : <div className='toolbars' >
                    <img src={logoguide} onMouseMove={(e:any) => {
                        // e.stopPropagation();
                        this.setState({
                            hiddenshow: true
                        })
                    }}
                        onMouseLeave={() => { 
                            this.setState({
                                hiddenshow: false
                            })
                        }} style={{ width: '50px', cursor: 'pointer', zIndex: 999 }} />
                </div>}
             {hiddenshow?<div style={{width:'50px',height:"200px",position:'fixed',bottom:'100px',zIndex:999}}   onMouseMove={() => {
                    this.setState({
                         hiddenshow: true
                    })
                }} onMouseLeave={() => {
                         setTimeout(()=>{
                            this.setState({
                                hiddenshow: false, 
                              
                            })
                         },3000)
                       
                  
                  
                }} >  <div className='toolbarswei' style={{opacity:kefuweixinsVisiable?1:1}}>
                        {/* kefuweixin */}
                        <Tooltip placement="leftTop" title={<img src={logouts} style={{ width: '100px' }} />}>
                            <img src={kefuweixinsVisiable?kefuweixins:kefuweixin}   onMouseMove={() => {
                            this.setState({
                                kefuweixinsVisiable: true
                            })
                        }} onMouseLeave={() => {
                            this.setState({
                                kefuweixinsVisiable:false   
                            })}} style={{ width: '35px', cursor: 'pointer', zIndex: 999 }} />
                        </Tooltip>

                    </div>
                    <div className='toolbarskefulogo' style={{opacity:kefulogosVisiable?1:1}}>
                        {/* kefuweixin */}
                        <img src={kefulogosVisiable?kefulogo:kefulogos} title='点击咨询客服'
                        
                        onMouseMove={() => {
                            this.setState({
                                kefulogosVisiable: true
                            })
                        }} onMouseLeave={() => {
                            this.setState({
                                kefulogosVisiable:false   
                            })
                          
                        }}   onClick={() => {
                            window.open('http://wpa.qq.com/msgrd?v=3&uin=2050096920&site=qq&menu=yes')
                            this.setState({
                                hiddenshow: true
                            })
                        }} style={{ width: '35px', cursor: 'pointer', zIndex: 999 }} />
                    </div>
                  
                    <div className='toolbarsfankui' style={{opacity:kefuyijiansVisiable?1:1}}>
                        {/* kefuweixin */}
                        <img src={kefuyijiansVisiable?kefuyijians:kefuyijian} title='点击提交反馈意见' onClick={() => {
                            this.setState({
                                onCancelvisible: true
                            })
                        }}   onMouseMove={() => {
                            this.setState({
                                kefuyijiansVisiable: true
                            })
                        }} onMouseLeave={() => {
                            this.setState({
                                kefuyijiansVisiable:false   
                            })
                          
                        }} style={{ width: '28px', cursor: 'pointer', zIndex: 999 }} />
                    </div>                
                    <div className='toolbarshang' style={{opacity:kefushangVisiable?1:1}}>
                        <img src={kefushangVisiable?kefushangs:kefushang} onMouseMove={() => {
                            this.setState({
                                kefushangVisiable: true
                            })
                        }} onMouseLeave={() => {

                            this.setState({
                                kefushangVisiable:false   
                            })
                          
                        }} onClick={()=>{
                            window.scrollTo(0,0);
                            // (document.getElementById('topAnchor')as any).scrollTop=0;
                            // document.body.scrollTop = 61;
                        // document.documentElement.scrollTop = 0;
                        }} style={{ width: '35px', cursor: 'pointer', zIndex: 999 }} />
                       
                    </div>                 
                    </div>:null }
                {/* kefulogo */}
                <div className="toolbar" style={{ display: 'block' }}>


                    {/* {hiddenshow ? <img src={logokefu} style={{ width: '48px', cursor: 'pointer' }} title='点击展开' onClick={() => {
              this.setState({
                hiddenshow: true,
                // showhiddens: false
              })
            }} /> : null} */}


                </div>
                <ModalCompent visible={onCancelvisible} onCancel={() => {
                    this.setState({
                        onCancelvisible: false
                    })
                }} />
            </div>
        )
    }
}