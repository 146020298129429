import req,{get} from '../utils/request';
import { Model } from 'dva';
const help: Model = {
    namespace: 'help',
    state: {

    },
    effects: {
        //获取帮助分类
        *queryHelp({ payload: { params, cb } }, { call, put }) {
            const result = yield call(req, '/api/getHelpTypes', {
                method: 'POST',
                body: params
            })
            yield put({
                type: 'setHelp',
                payload: result.data
            });
            cb && cb(result)
        },
        //获取帮助子分类
        *querySubclass({ payload: { params, cb } }, { call, put }) {
            const result = yield call(req, '/api/getChildTypes', {
                method: 'POST',
                body: params
            })
            yield put({
                type: 'setHelpSubclass',
                payload: result
            });
            cb && cb(result)
        },
        //获取帮助详情
        *queryHelpfordetails({ payload: { params, cb } }, { call, put }) {
            const result = yield call(get, '/api/getTypeDetails', {
                // method: 'POST',
                 params
            })
            yield put({
                type: 'setHelpfordetails',
                payload: result
            });
            cb && cb(result)
        },
        *queryHelpGroupinformation({ payload: { params, cb } }, { call, put }) {
            const result = yield call(req, '/api/getHelpDetails', {
                method: 'POST',
                body: params
            })
            yield put({
                type: 'setHelpGroupinformation',
                payload: result
            });
            cb && cb(result)
        },
        *queryHelpsearch({ payload: { params, cb } }, { call, put }) {
            const reslut = yield call(req, '/api/getSearchHelps', {
                method: 'POST',
                body: params
            })
            yield put({
                type: 'setHelpSearch',
                payload: reslut.data
            })
            cb&&cb(reslut)
        }

    },
    reducers: {
        setHelp(state, { payload: classification }: any) {
            return {
                ...state,
                classification
            }
        },
        setHelpSearch(state,{payload:HelpSearch}:any){
            return {
                ...state,
                HelpSearch
            }
        },
        setHelpSubclass(state, { payload: Subclass }: any) {
            return {
                ...state,
                Subclass
            }
        },
        setHelpfordetails(state, { payload: fordetails }: any) {
            return {
                ...state,
                fordetails
            }
        },
        setHelpGroupinformation(state, { payload: Groupinformation }: any) {
            return {
                ...state,
                Groupinformation
            }
        },

    }
}
export default help