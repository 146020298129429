import * as React from 'react';
const { lazy } = React;
/**
 * 路由配置文件 默认按需加载
 */
export const routes = [
  {
    path: '/',
    redirect: 'dynamic'
  },
  {
    path: '/403',
    component: lazy(() => import('./pages/Permissions')),
    title: '403',
    // redirect: 'dynamic'
  },
  {
    path: '/pagesD/bind/bind',
    component: lazy(() => import('./pages/pagesD/bind/bind')),
    title: '绑定',
    // redirect: 'dynamic'
  },
  {
    path: '/home',
    component: lazy(() => import('./components/Home')),
    title: 'home',
    // redirect: 'dynamic'
  },
  {
    path: '/Mine',
    component: lazy(() => import('./pages/Mine')),
    title: 'Mine',
    // redirect: 'dynamic'
  },
  {
    path: '/Protocal',
    component: lazy(() => import('./pages/Protocol')),
    title: 'Protocal'
  },
  {
    path: '/MonitoringCompany',
    component: lazy(() => import('./pages/MonitoringCompany')),
    title: 'MonitoringCompany',
    // redirect: 'dynamic'
  },
  {
    path: '/Exportthereport/:reviewId',
    component: lazy(() => import('./pages/Exportthereport')),
  },

  {
    path: '/brower',
    component: lazy(() => import('./brower/index')),
    title: '浏览器',
  },
  {
    path: '/help',
    component: lazy(() => import('./Help/index')),
    title: '帮助中心',
  },
  {
    path: '/Assisttohelp',
    component: lazy(() => import('./Help/AssistTohelp')),
    title: '帮助中心',
  },
  {
    path: '/helpSerch',
    component: lazy(() => import('./Help/Search')),
    title: '帮助中心',
  },
  {
    path: '/helpgroup',
    component: lazy(() => import('./Help/Groupinformation')),
  },

  {
    path: '/dynamic',
    component: lazy(() => import('./pages/dynamic/Dynamics')),
    title: '评审通-动态',
  },
  {
    path: '/work',
    cache: true,
    component: lazy(() => import('./pages/work/Work')),
    title: '评审通-工作',
    routes: [
      {
        path: '/',
        component: lazy(() => import('./pages/work/workbench/Workbench')),
      },
    
      {
        path: '/review',
        cache: true,
        component: lazy(() => import('./pages/work/review/ReviewSider')),
        routes: [
          {
            path: '/templates',
            cache: true,
            component: lazy(() => import('./pages/work/review/Templates')),
          },
          {
            path: '/help',
            cache: true,
            component: lazy(() => import('./pages/work/review/Help')),
          },
          {
            path: '/initiate',
            cache: true,
            component: lazy(() => import('./pages/work/review/Initiate')),
          },
          {
            path: '/detail/:reviewId',
            cache: true,
            component: lazy(() => import('./pages/work/review/ReviewDetail')),
          },
          {
            path: '/export',
            component: lazy(() => import('./pages/work/review/exportPreview')),
          },
          {
            path: '/querypst',
            cache: true,
            component: lazy(() => import('./pages/work/review/queryAndExport/querypst')),
          },
          {
            path: '/exportpst',
            cache: true,
            component: lazy(() => import('./pages/work/review/queryAndExport/exportpst')),
          },
          {
            path: '/adminanalysis',
            cache: true,
            component: lazy(() => import('./pages/work/review/Dataanalysis/Administrator')),
          },
          {
            path: '/personalanalysis',
            cache: true,
            component: lazy(() => import('./pages/work/review/Dataanalysis/Personal')),
          },
          {
            path: '/dataanalysis/detail',
            cache: true,
            component: lazy(() => import('./pages/work/review/Dataanalysis/details')),
          },
          {
            path: '/dataanalysis/detailyear',
            cache: true,
            component: lazy(() => import('./pages/work/review/Dataanalysis/detailyear')),
          },
          {
            path: '/multipleanalysis',
            cache: true,
            component: lazy(() => import('./pages/work/review/Dataanalysis/Multiple')),
          },
          {
            path: '/princal',
            cache: true,
            component: lazy(() => import('./pages/work/review/Dataanalysis/princal')),
          },
          {
            path: '/partneranalysis',
            cache: true,
            component: lazy(() => import('./pages/work/review/Dataanalysis/Partner')),
          },
          {
            path: '/templatemgt',
            cache: true,
            component: lazy(() => import('./pages/work/review/templatemgt/Templatemgt')),
          },
          {
            path: '/createTemplate',
            component: lazy(() => import('./pages/work/review/templatemgt/CreateTemplate')),
          },
          {
            path: '/tempGroupSort',
            component: lazy(() => import('./pages/work/review/templatemgt/GroupSort')),
          },
          {
            path: '/processmgt',
            cache: true,
            component: lazy(() => import('./pages/work/review/processmgt/Processmgt')),
          },
          {
            path: '/processGroupSort',
            component: lazy(() => import('./pages/work/review/processmgt/GroupSort')),
          },
          {
            path: '/recordmgt',
            component: lazy(() => import('./pages/work/review/recordmgt/Recordmgt')),
          },
          {
            path: '/createProcess',
            component: lazy(() => import('./pages/work/review/processmgt/CreateProcess')),
          },
          {
            path: '/exportmgt',
            component: lazy(() => import('./pages/work/review/exportmgt/Exportmgt')),
          },
          {
            path: '/yearreport',
            component: lazy(() => import('./pages/work/review/yearreport/index')),
          },
          {
            path: '/enclosure',
            component: lazy(() => import('./pages/work/review/yearreport/enclosure')),
          },
          {
            path: '/exportYear',
            component: lazy(() => import('./pages/work/review/yearreport/exportYear')),
          },
          {
            path: '/yearfile',
            component: lazy(() => import('./pages/work/review/yearreport/yearfile')),
          },
          {
            path: '/projectlist',
            component: lazy(() => import('./pages/work/review/yearreport/projectlist')),
          },
          {
            path: '/attachments',
            component: lazy(() => import('./pages/work/review/yearreport/attachments')),
          },
          {
            path: '/createReport',
            component: lazy(() => import('./pages/work/review/exportmgt/CreateReport')),
          },
          {
            path: '/createRecordReport',
            component: lazy(() => import('./pages/work/review/recordmgt/CreateReport')),
          },
          {
            path: '/reportGroupSort',
            component: lazy(() => import('./pages/work/review/exportmgt/GroupSort')),
          },
          {
            path: '/recordGroupSort',
            component: lazy(() => import('./pages/work/review/recordmgt/GroupSort')),
          },
          {
            path: '/othersmgt',
            component: lazy(() => import('./pages/work/review/othersmgt/Othersmgt')),
          },
          {
            path: '/materialsmgt',
            component: lazy(() => import('./pages/work/review/materialsmgt/materialsmgt')),
          },
          {
            path: '/projectsmgt',
            component: lazy(() => import('./pages/work/review/projectsmgt/projectsmgt')),
          },
          {
            path: '/engineeringsmgt',
            component: lazy(() => import('./pages/work/review/engineeringsmgt/engineeringsmgt')),
          },
          {
            path: '/customizesmgt',
            component: lazy(() => import('./pages/work/review/customizesmgt/customizesmgt')),
          },
          {
            path: '/labelsmgt',
            component: lazy(() => import('./pages/work/review/labelsmgt/labelsmgt')),
          },

          {
            path: '/:reviewType(all|reviewing|reviewed|reviewedbyme|pendingApproval|pendingReceive|initiated|copytome|archived|pendingAssign|recyclebin|cancled|back)',
            cache: true,
            component: lazy(() => import('./pages/work/review/Reviews')),
          }
        ]
      },
      {
        path: '/notice',
        cache: true,
        component: lazy(() => import('./pages/work/notice/Notices')),
        title: '评审通-公告',
        routes: [
          {
            path: '/',
            cache: true,
            component: lazy(() => import('./pages/work/notice/NoticeList')),
          },
          {
            path: '/create',
            component: lazy(() => import('./pages/work/notice/createNotice/Create')),
          },
          {
            path: '/details/:noticeId',
            component: lazy(() => import('./pages/work/notice/Details')),
          }
        ]
      },
      /**审批 */
      {
        path: '/approval',
        cache: true,
        component: lazy(() => import('./pages/work/approval/MyApprovalSider')),
        routes: [
          {
            path: '/',
            redirect: '/all',
          },
          {
            path: '/:approvalType(all|pending|approved|initiate|ccApproval|archive)', // 待审批/我已审批/我发起的/抄送给我的/归档
            cache: true,
            component: lazy(() => import('./pages/work/approval/Approval')),
          },
          {
            path: '/management', // 审批管理
            cache: true,
            component: lazy(() => import('./pages/work/approval/management/Index')),
          },
          {
            path: '/groupSort', // 分组排序
            component: lazy(() => import('./pages/work/approval/management/Group')),
          },
          {
            path: '/querysp', // 分组排序
            component: lazy(() => import('./pages/work/approval/exportsp/querysp')),
          },
          {
            path: '/exportsp',
            cache: true,
            component: lazy(() => import('./pages/work/approval/exportsp/exportsp')),
          },
          {
            path: '/create', // 新建审批
            cache: true,
            component: lazy(() => import('./pages/work/approval/CreateApproval')),
          },
          {
            path: '/template',
            cache: true,
            component: lazy(() => import('./pages/work/approval/Template')),
          },
          {
            path: '/newCreate', // 审批管理-新建审批
            cache: true,
            component: lazy(() => import('./pages/work/approval/management/Create')),
          },
          {
            path: '/help', // 审批管理-新手帮助
            cache: true,
            component: lazy(() => import('./pages/work/approval/help/index')),
          },
          {
            path: '/exportmgt',
            component: lazy(() => import('./pages/work/approval/exportmgt/Exportmgt')),
          },
          {
            path: '/reportGroupSort',
            component: lazy(() => import('./pages/work/approval/exportmgt/GroupSort')),
          },
          {
            path: '/createReport',
            component: lazy(() => import('./pages/work/approval/exportmgt/CreateReport')),
          },
        ]
      },
      /**协助 */
      {
        path: '/assist',
        cache: true,
        component: lazy(() => import('./pages/work/assist/Assist')),
        routes: [
          {
            path: '/',
            cache: true,
            component: lazy(() => import('./pages/work/assist/MyAssist')),
          },
          {
            path: '/template',
            component: lazy(() => import('./pages/work/assist/Template')),
          },
          {
            path: '/help', // 审批管理
            cache: true,
            component: lazy(() => import('./pages/work/assist/Template')),
          },
          // {
          //   path: '/templatepst',
          //   component: lazy(() => import('./pages/work/assist/Templatepst')),
          // }
        ]
      },
      /**考勤打卡 */
      {
        path: '/clockin',
        cache: true,
        component: lazy(() => import('./pages/work/clockin/MyClockin')),
        routes: [
          {
            path: '/',
            redirect: '/all',
          },
          {
            path: '/all', // 待审批/我已审批/我发起的/抄送给我的/归档
            cache: true,
            component: lazy(() => import('./pages/work/clockin/Clockin')),
          },
          {
            path: '/myRecord',
            cache: true,
            component: lazy(() => import('./pages/work/clockin/myRecord/index')),
          },
          {
            path: '/myApplication',
            cache: true,
            component: lazy(() => import('./pages/work/clockin/myApplication/index')),
          },
          {
            path: '/checkSet', // 审批管理
            cache: true,
            component: lazy(() => import('./pages/work/clockin/checkSet/index')),
          },
          {
            path: '/shiftSet', // 审批管理
            cache: true,
            component: lazy(() => import('./pages/work/clockin/Shiftset/index')),
          },
          {
            path: '/AttendanceStatistics', // 考勤统计
            cache: true,
            component: lazy(() => import('./pages/work/clockin/AttendanceStatistics/index')),
          },
          {
            path: '/ClockinTime', // 打卡时间
            cache: true,
            component: lazy(() => import('./pages/work/clockin/ClockinTime/index')),
          },
          {
            path: '/Applicationrecord', // 申请记录
            cache: true,
            component: lazy(() => import('./pages/work/clockin/Applicationrecord/index')),
          },
          {
            path: '/ClockinExport', // 导出记录
            cache: true,
            component: lazy(() => import('./pages/work/clockin/ClockinExport/index')),
          },
        ]
      },
    ]
  },
  /**文档 */
  {
    path: '/doc',
    component: lazy(() => import('./pages/doc/doc')),
    title: '评审通-文档',
    routes: [
      {
        path: '/',
        redirect: '/personal'
      },
      {
        path: '/dynamic',
        component: lazy(() => import('./pages/doc/dynamic')),
        title: '评审通-文档',
      },
      {
        path: '/recently',
        component: lazy(() => import('./pages/doc/recently')),
        title: '评审通-文档',
      },
      {
        path: '/personal',
        component: lazy(() => import('./pages/doc/personal')),
        title: '评审通-文档',
      },
      {
        path: '/company/:companyId',
        component: lazy(() => import('./pages/doc/company')),
        title: '评审通-文档',
      }
    ]
  },
  {
    path: '/work/systmgt',
    component: lazy(() => (() => <div>系统管理</div>) as any),
    title: '评审通-系统管理'
  },
  {
    path: '/contact',
    component: lazy(() => import('./pages/contact/contact')),
    title: '评审通-通讯录',
    routes: [
      {
        path: '/',
        redirect: '/company'
      },
      {
        path: '/company/:companyId',
        component: lazy(() => import('./pages/contact/company')),
      }
    ]
  },
  {
    path: '/settings',
    component: lazy(() => import('./pages/settings/settings')),
    routes: [
      {
        path: '/percenter',
        component: lazy(() => import('./pages/settings/percenter')),
      },
      {
        path: '/profile',
        component: lazy(() => import('./pages/settings/profile')),
      },
      {
        path: '/invitation',
        component: lazy(() => import('./pages/settings/invitation')),
      },
      {
        path: '/dissolution',
        component: lazy(() => import('./pages/settings/dissolution')),
      }
    ]
  },
  {
    path: '/companyRegister',
    component: lazy(() => import('./pages/companyRegister')),
  },
]