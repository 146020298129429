import req, { get } from "../utils/request";
import { message } from "antd";
import { Model } from "dva";
const Review: Model = {
  namespace: 'Review',
  state: {
  },
  effects: {
    /** --- 评审通模板 --- */

    /** 经典模板信息 */
    *queryClassicTemplates({ payload: cb }, { call, put }) {
      const result = yield get('/api/c_pst_get_classic_pst_template');
      if (result.status === 'success') {
        yield put({ type: 'setClassicTemplates', payload: result.data });
        cb && cb(result.data);
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    *queryClassicsingle_files({ payload: { params, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_single_files', {
        params
      });
      if (result.status === 'success') {
        yield put({ type: 'setClassicsingle_files', payload: result.files });
        cb && cb(result.files);
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    // const result = await get('/api/c_pst_get_single_files', {
    //   params: {
    //     id: allow_download
    //   }
    // })
    /** 列表信息 */
    *queryTemplates({ payload: cb }, { call, put }) {
      const result = yield get('/api/c_pst_get_company_pst_template');
      if (result.status === 'success') {
        yield put({ type: 'setTemplates', payload: result.data });
        cb && cb(result.data.enable);
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    /** 分组信息 */
    *queryTemplateGroup({ payload: cb }, { call, put }) {
      const result = yield get('/api/c_pst_get_pst_template_type');
      if (result.status === 'success') {
        yield put({ type: 'setTemplateGroup', payload: result.data });
        cb && cb(result.data);
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    /** 指定模板详细信息 (by id) */
    *queryTemplateById({ payload: { id, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_single_pst_template', {
        params: {
          id
        }
      });
      cb && cb(result.data)
      if (result.status === 'success') {
        yield put({ type: 'setTemplate', payload: result.data });
      } else {
        message.error(result.message);
      }
    },

    /** --- 评审通流程 --- */
    /** 列表信息 */
    *queryProcesses({ payload: { params, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_company_process_template');
      if (result.status === 'success') {
        cb && cb();
        yield put({ type: 'setProcesses', payload: result.data });
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    /** 分组信息 */
    *queryProcessGroup({ payload: cb }, { call, put }) {
      const result = yield get('/api/c_pst_get_process_template_type');
      if (result.status === 'success') {
        yield put({ type: 'setProcessGroup', payload: result.data });
        cb && cb(result.data);
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    *queryApprovalSearch({ payload: { params, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_search_pst_by_name', { params })
      if (result.status == 'success') {
        cb && cb(result)
        yield put({
          type: 'setApprovalSearch',
          payload: result
        });
      }
    },
    *queryApprovalSearchall({ payload: { params, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_union_search_by_state', { params })
      if (result.status == 'success') {
        cb && cb(result)
        yield put({
          type: 'setApprovalSearchall',
          payload: result
        });
      }
    },
    /** 指定流程详细信息 (by id) */
    *queryProcessById({ payload: { id, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_single_process_template', {
        params: {
          id
        }
      });
      if (result.status === 'success') {
        yield put({ type: 'setProcess', payload: result.data });
        cb && cb(result.data);
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    /** --- 评审通报告导出 --- */
    *queryReports({ payload }, { call, put }) {
      const result = yield get('/api/c_pst_exportTemplateList');
      if (result.status === 'success') {
        yield put({ type: 'setReports', payload: result.data });
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    *queryClassicReports({ payload }, { call, put }) {
      const result = yield get('/api/c_pst_exportTemplatePublic');
      if (result.status === 'success') {
        yield put({ type: 'setClassicReports', payload: result.data });
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    /** --- 评审通台账报告导出 --- */
    *queryRecordReports({ payload }, { call, put }) {
      const result = yield get('/api/c_pst_companyRecordTemplateList');
      if (result.status === 'success') {
        yield put({ type: 'setRecordReports', payload: result.data });
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    *queryRecordClassicReports({ payload }, { call, put }) {
      const result = yield get('/api/c_pst_recordTemplatePublic');
      if (result.status === 'success') {
        yield put({ type: 'setRecordClassicReports', payload: result.data });
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    /** 分组信息 */
    *queryReportGroup({ payload: cb }, { call, put }) {
      const result = yield get('/api/c_pst_getExportTypeList');
      // if (result.status === 'success') {
      yield put({ type: 'setReportGroup', payload: result });
      cb && cb(result);
      // } else {
      //   message.error('服务器异常，请稍后再试')
      // }
    },
    /** 分组信息 */
    *queryRecordReportGroup({ payload: cb }, { call, put }) {
      const result = yield get('/api/c_pst_getCompanyRecordTypeList');
      // if (result.status === 'success') {
      yield put({ type:'setRecordReportGroup', payload: result });
      cb && cb(result);
      // } else {
      //   message.error('服务器异常，请稍后再试')
      // }
    },
    /** 指定流程详细信息 (by id) */
    *queryReportById({ payload: { id, cb } }, { call, put }) {
      const result = yield req('/api/c_pst_exportTemplateEdit', {
        method: 'POST',
        body: {
          id
        }
      });
      // console.log(result.data)
      if (result.status === 'success') {
        yield put({ type: 'setReport', payload: result.data });
        cb && cb(result.data[0]);
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
  /** 指定台账详细信息 (by id) */
  *queryRecordReportById({ payload: { id, cb } }, { call, put }) {
    const result = yield req('/api/c_pst_companyRecordTemplateEdit', {
      method: 'POST',
      body: {
        id
      }
    });
    // console.log(result.data)
    if (result.status === 'success') {
      yield put({ type: 'setRecordReport', payload: result.data });
      cb && cb(result.data[0]);
    } else {
      message.error('服务器异常，请稍后再试')
    }
  },
    /** 指定流程详细信息 (by id) */
    *queryExportpacks({ payload }, { call, put }) {
      const result = yield call(get, '/api/c_pst_exportPackageLike');
      yield put({ type: 'setExportpacks', payload: result });
    },
    /** --- 评审通其他 --- */
    /** 获取评审通基础表单数据 */
    *querySomeBaseData({ payload: { id, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_basic_form_data');

      if (result.status === 'success') {
        yield put({ type: 'setSomeBaseData', payload: result.data });
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    *queryCanLinkReviews({ payload: { params, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_can_related', {
        params
      });

      if (result.status === 'success') {
        cb && cb(result);
        yield put({ type: 'setCanLinkReviews', payload: result });
      } else {
        message.error('服务器异常，请稍后再试')
      }
    },
    /** --- 评审通 --- */
    *queryReviewsByState({ payload: { params, cb } }, { call, put }) {
      // const result = yield get('/api/c_pst_search_by_state', {
      const result = yield get('/api/c_pst_search_by_state', {
        params
      });
      if (result.status === 'success') {
        cb && cb(result);
        yield put({ type: 'setReviews', payload: result });
      }
    },
    /*同账复制**/
    // c_pst_copy_operate_record
    *queryCopyOperate({ payload: { params, cb } }, { call, put }) {
      const result = yield req('/api/c_pst_copy_operate_record', {
        method: 'POST',
        body: params
      });
      cb && cb(result);
      if (result.status == 'success') {
        message.success('台账复制成功')
      } else {
        message.error(result.message);
      }
    },
    // *queryReviewsByStateRole({ payload: { params, cb } }, { call, put }) {
    //   const result = yield req('/api/c_pst_union_search_all', {
    //     method: 'POST',
    //     body: params
    //   });
    //   if (result.status === 'success') {
    //     cb && cb();
    //     yield put({ type: 'setReviews', payload: result });
    //   }
    // },
       /**
     * 详情
     */
    // *queryApprovalDetail({ payload: { params, cb } }, { call, put }) {
    //   const result = yield call(get, `/api/c_approval_detail`, {
    //     params
    //   })
    //   yield put({
    //     type: 'setApprovalDetail',
    //     payload: result
    //   });
    //   cb && cb(result)
    // },
    *queryReviewsByStateRole({ payload: { params, cb } }, { call, put }) {
      const result = yield req('/api/c_pst_union_search_all_page', {
        method: 'POST',
        body: params
      });
      if (result.status === 'success') {
        cb && cb();
        yield put({ type: 'setReviews', payload: result });
      }
    },
    *queryReviewsByStateAndRole({ payload: { params, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_union_search_by_state', {
        params
      });
      if (result.status === 'success') {
        cb && cb();
        yield put({ type: 'setReviews', payload: result });
      }
    },
    /** --- 评审通多条件查询导出 --- */
    *queryReviewsByMutiAndRole({ payload: { params, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_union_search_by_muti', {
        params
      });
      if (result.status === 'success') {
        cb && cb();
        yield put({ type: 'setReviews', payload: result });
      }
    },

      // /** --- 审批多条件查询导出 --- */
      // *queryApprovalByMutiAndRole({ payload: { params, cb } }, { call, put }) {
      //   const result = yield get('/api/c_pst_search_approve_by_name', {
      //     params
      //   });
      //   // if (result.status === 'success') {
      //     cb && cb(result,'success', result.approval_data.length, result.all_count);
      //     yield put({ type: 'setApproval', payload: result });
      //   // }
      // },

      // *queryApprovalList({ payload: params, cb }, { call, put }) {
      //   const result = yield call(get, '/api/c_approval_list', {
      //     params
      //   })
      //   if (result.approval_data) {
      //     cb && cb(result,'success', result.approval_data.length, result.all_count)
      //     yield put({
      //       type: 'setApproval',
      //       payload: result
      //     });
      //   }
      // },
    *exportReviews({ payload: { params, cb } }, { call, put }) {
      const result = yield req('/api/c_pst_exportReviews', {
        method: 'POST',
        body: params

      });
      cb && cb(result)
      // yield get('/api/c_pst_exportReviews', {
      //   params
      // });
      // if (result.status === 'success') {
      //   yield put({ type: 'setReviews', payload: result.data });
      // }
    },
    // 年报导出
    *exportReviewsyear({ payload: { params, cb } }, { call, put }) {
      const result = yield req('/api/c_pst_exportReviewsYear', {
        method: 'POST',
        body: params

      });
      cb && cb(result)
      // yield get('/api/c_pst_exportReviews', {
      //   params
      // });
      // if (result.status === 'success') {
      //   yield put({ type: 'setReviews', payload: result.data });
      // }
    },
    /** 评审通单条详情 */
    *queryReviewById({ payload: { id, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_single_detail', {
        params: {
          id
        }
      });
      if (result.status === 'success') {
        // console.log(result)
        const { data, files } = result;
        if (data.state == '待接收') {
          data.btn_status.btn_data_alter = false;
          // data.btn_status.btn_data_assist = false;
        }
        if (data.state == '已归档' || data.state == '已撤回' || data.state == '已作废' || data.state == '待指派' || data.state == '待接收' || data.state == '已拒绝' || data.btn_status.btn_data_alter == false) {
          data.btn_status.btn_data_assist = false;
        }
        else {
          data.btn_status.btn_data_assist = true;
        }
        if (data.state == '待接收') {
          data.btn_status.btn_data_alter = false;
        }

        yield put({ type: 'setReview', payload: { data, files } });
        cb && cb(data, files);
      }
    },
    /** 评审通 相关操作时间轴 */
    *queryTimeline({ payload: { params, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_operate_record', {
        params
      });
      if (result.status === 'success') {
        // console.log(result)
        let { data, new_data, collaborative_data } = result;
        // if (new_data.length > 0) {
        data = [...data, ...new_data];
        // (new Date(text.created_at)).getTime()
        data.sort(function (pro: any, item: any) {
          if ((new Date(pro.created_at)).getTime() < (new Date(item.created_at)).getTime()) return -1
          else if ((new Date(pro.created_at)).getTime() > (new Date(item.created_at)).getTime()) return 1
          else return 0
        });
        for (let i = 0; i < data.length; i++) {
          data[i].key = i;
        }
        // }
        yield put({ type: 'setTimeline', payload: { collaborative_data, data, new_data } });
        cb && cb(result)
      }
    },
    /** 处理评审详情的一些按钮操作 */
    *mutationsReview({ payload: { pathname, body, cb } }, { call, put }) {
      const result = yield req(pathname, {
        method: 'POST',
        body
      });
      cb && cb();
      if (result.status === 'success') {
      } else {
        message.error(result.message)
      }
    },
    /** 用户所有追加的表单数据 */
    *queryAllAddformData({ payload: { id, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_merge_data', {
        params: { pst_id: id }
      });
      if (result.status === 'success') {
        yield put({ type: 'setAllAddformData', payload: result });
        cb && cb(result.form_data);
      }
    },
    /** 关联审批列表 */
    *queryLinkApprovals({ payload: { id, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_related_approval', {
        params: { pst_id: id }
      });
      if (result.status === 'success') {
        cb && cb(result)
        yield put({ type: 'setLinkApprovals', payload: result });
      }
    },
    /** 关联评审列表 */
    *queryLinkReviews({ payload: { id, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_self_related', {
        params: { pst_id: id }
      });
      if (result.status === 'success') {
        cb && cb(result)
        yield put({ type: 'setLinkReviews', payload: result.data });
      }
    }
  },
  reducers: {
    /** 设置评审通模板信息 to state */
    setTemplates(state, { payload }: any) {
      return {
        ...state,
        templates: payload
      }
    },
    setClassicTemplates(state, { payload }: any) {
      return {
        ...state,
        classicTemplates: payload
      }
    },
    setClassicsingle_files(state, { payload }: any) {
      return {
        ...state,
        classicfiles: payload
      }
    },
    setTemplateGroup(state, { payload }: any) {
      return {
        ...state,
        templateGroup: payload
      }
    },
    setProcesses(state, { payload }: any) {
      return {
        ...state,
        processes: payload
      }
    },
    setProcessGroup(state, { payload }: any) {
      return {
        ...state,
        processGroup: payload
      }
    },
    setTemplate(state, { payload }: any) {
      return {
        ...state,
        template: payload
      }
    },
    setProcess(state, { payload }: any) {
      return {
        ...state,
        process: payload
      }
    },
    setReportGroup(state, { payload }: any) {
      return {
        ...state,
        reportGroup: payload
      }
    },
    setRecordReportGroup(state, { payload }: any) {
      return {
        ...state,
        recordReportGroup: payload
      }
    },
    setReport(state, { payload }: any) {
      return {
        ...state,
        report: payload
      }
    },
    setRecordReport(state, { payload }: any) {
      return {
        ...state,
        recordReport: payload
      }
    },
    setReports(state, { payload }: any) {
      return {
        ...state,
        reports: payload
      }
    },
    setClassicReports(state, { payload }: any) {
      return {
        ...state,
        classicReports: payload
      }
    },
    setRecordReports(state, { payload }: any) {
      return {
        ...state,
        recordReports: payload
      }
    },
    setRecordClassicReports(state, { payload }: any) {
      return {
        ...state,
        recordClassicReports: payload
      }
    },
    setExportpacks(state, { payload }: any) {
      return {
        ...state,
        exportpacks: payload
      }
    },
    /** --- 评审通其他 --- */
    /** 获取评审通基础表单数据 */
    setSomeBaseData(state, { payload }: any) {
      return {
        ...state,
        someBaseData: payload
      }
    },
    setCanLinkReviews(state, { payload }: any) {
      return {
        ...state,
        canLinkReviews: payload
      }
    },
    /** --- 评审通 --- */
    /** 评审通列表信息 */
    setReviews(state, { payload }: any) {
      return {
        ...state,
        reviews: payload
      }
    },
    setReviewsRole(state, { payload }: any) {
      return {
        ...state,
        reviewsRole: payload
      }
    },
    /** 评审通搜索信息 */
    setApprovalSearch(state, { payload }: any) {
      return {
        ...state,
        reviews: payload
      }
    },
       /** 评审通搜索all信息 */
    setApprovalSearchall(state, { payload }: any) {
      return {
        ...state,
        reviews: payload
      }
    },
    /** 某个评审通详情 */
    setReview(state, { payload: { data, files } }: any) {
      return {
        ...state,
        review: data,
        files
      }
    },
    /** 评审通 所有用户追加的表单 */
    setAllAddformData(state, { payload }: any) {
      return {
        ...state,
        allAddFormData: payload
      }
    },
    /** 某个评审通动态时间轴 */
    setTimeline(state, { payload }: any) {
      return {
        ...state,
        timeline: payload
      }
    },
    /** 关联审批列表 */
    setLinkApprovals(state, { payload }: any) {
      return {
        ...state,
        linkApprovals: payload
      }
    },
    /** 关联评审列表 */
    setLinkReviews(state, { payload }: any) {
      return {
        ...state,
        linkReviews: payload
      }
    },
  }
}
export default Review