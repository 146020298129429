import React from 'react';
import AppHeader from './layouts/header';
import Customerservice from './layouts/Customerservice';
import { ConfigProvider } from 'antd';

import moment from 'moment';
// import Browser from "../src/browser/index";
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import 'intro.js/introjs.css';
moment.locale('zh-cn');

class App extends React.Component {


  render() {
    return (

      <ConfigProvider locale={zh_CN}>
        <>
          <AppHeader />
          <Customerservice />
          {this.props.children}
        </>
      </ConfigProvider>
    );
  }
}

export default App
