import req, { get } from '../utils/request';
import { Model } from 'dva';
import { message } from 'antd';
const Assist: Model = {
  namespace: 'Assist',
  state: {
    assistList: [],
    assistDetails: {},
    currentStatus: 'all',
  },
  effects: {
    /**
     * 查找协助列表
     * @param ____  payload 用户传过来的参数
     * @param param1 sagaEffects方法
     */
    *queryAssistList({ payload: params }, { call, put }) {
      const result = yield call(get, '/api/c_assist_taskList', {
        params
      })
      yield put({
        type: 'setAssistList',
        payload: { status: params.status, result }
      });

    },
    *queryReviewById({ payload: { id, cb } }, { call, put }) {
      const result = yield get('/api/c_pst_get_single_detail', {
        params: {
          id
        }
      });
      if (result.status === 'success') {
        // console.log(result)
        const { data, files } = result;
        if ( data.state == '待接收') {
          data.btn_status.btn_data_alter = false;
          // data.btn_status.btn_data_assist = false;
        } 
        if (data.state == '已归档' || data.state == '已撤回' || data.state == '已作废' || data.state == '待指派' || data.state == '待接收' || data.state == '已拒绝'||data.btn_status.btn_data_alter==false) {
          data.btn_status.btn_data_assist = false;
        } 
        else { 
          data.btn_status.btn_data_assist = true;
        }
        if(data.state=='待接收'){
          data.btn_status.btn_data_alter = false;  
        }
        
        yield put({ type: 'setPstAssist', payload: { data, files } });
        cb && cb(result);
      }
    },
    *queryPstAssist({ payload:{params,cb}}, { call, put }) {
      const result = yield call(get,'/api/c_pst_get_detail', {
        params
      })
       
      yield put({
        type: 'setPstAssist',
        payload: result
      });
       cb&&cb(result);
    },
    *queryAssistDetails({ payload: id, cb }, { call, put }) {

      const result = yield call(req, `/api/c_assist_taskDetail?id=${id}`)
     if(result.id){
      yield put({
        type: 'setAssistDetails',
        payload: result
      });
      cb && cb(result);
     }
    
    },
    *searchAssistList({ payload: params ,cb}, { call, put }) {
      const result = yield call(get, '/api/c_assist_search', {
        params
      })
       if(result.data){
        cb&&cb(result)
         if(result.data.length==0){
           message.success('未搜索到内容');
         }
        yield put({
          type: 'setSearchList',
          payload: result
        });
       }
     
    }
  },
  reducers: {
    /** 设置assistList */
    setAssistList(state, { payload: assistList }: any) {
      return {
        ...state,
        currentStatus: assistList.status,
        assistList: assistList.result
      }
    },
    setPstAssist(state,{payload:PstDeatails}:any){
          return {
            ...state,
            PstDeatails
          }
    },
    setAssistDetails(state, { payload: assistDetails }: any) {
      return {
        ...state,
        assistDetails
      }
    },
    setSearchList(state, { payload: assistList }: any) {
      return {
        ...state,
        currentStatus: 'all',
        assistList
      }
    }
  }
}
export default Assist